<template>
  <div>
    <form @submit.prevent="updatePatient">
      <gro-card :bordered="false">
        <div slot="body">
          <h2 class="mb-3">Update details</h2>
          <div class="mb-3">Update the personal details of an applicant. Referral status can be managed from the Patient Detail page.</div>
          <gro-input
              class="mb-3"
              required
              id="txt_firstName"
              name="txt_firstName"
              label="First name"
              type="text"
              placeholder="Applicant's first name"
              v-model="model.firstName"
              :is-error="$v.model.firstName.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_lastName"
              name="txt_lastName"
              label="Last name"
              type="text"
              placeholder="Applicant's last name"
              v-model="model.lastName"
              :is-error="$v.model.lastName.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_email"
              name="txt_email"
              label="Email"
              type="text"
              placeholder="Applicant's email address"
              v-model="model.email"
              :is-error="$v.model.email.$error"
          />

          <gro-input
              class="mb-3"
              required
              id="txt_phone"
              name="txt_phone"
              label="Phone number"
              type="tel"
              placeholder="Applicant's phone number"
              v-model="model.phone"
              :is-error="$v.model.phone.$error"
          />

          <gro-inline-date-of-birth
              class="mb-3"
              label="Date of birth"
              required
              v-model="model.dateOfBirth"
              :error-text="!$v.model.dateOfBirth.minAge ? 'Must be 18 years or older': 'Date of birth must be a valid date'"
              :is-error="$v.model.dateOfBirth.$error"
          />

          <div class="gro__input mb-3">
            <label class="gro__input__label" :class="{'has_error': $v.model.gender.$error}">Sex </label>
            <gro-radio v-model="model.gender" label="Male" value="Male"
                       :input-value="'Male'"></gro-radio>
            <gro-radio v-model="model.gender" class="ml-3" value="Female" label="Female"
                       :input-value="'Female'"></gro-radio>
            <gro-radio v-model="model.gender" class="ml-3" value="Other" label="Other"
                       :input-value="'Other'"></gro-radio>
          </div>


          <gro-input
              class="mb-3"
              placeholder="Applicant's post code"
              label="Post code"
              id="txt_postcode"
              name="txt_postcode"
              type="text"
              v-model="model.postcode"
              :is-error="$v.model.postcode.$error"
              required>
          </gro-input>


          <div class="gro__input">
            <label class="gro__input__label">Applicant's current weight*</label>
            <div class="row mb-4">
              <div v-if="userWeightPreference === 'stlbs'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_stones"
                    name="txt_stones"
                    type="number"
                    placeholder="Stones"
                    v-model="unitsModel.weightSt"
                    :is-error="$v.unitsModel.weightSt.$error"
                    :error-text="$v.unitsModel.weightSt.$error ? 'Please enter valid stones between 3 to 38' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    st
                  </div>
                </gro-input>
              </div>
              <div v-if="userWeightPreference === 'stlbs'" class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_pounds"
                    name="txt_pounds"
                    type="number"
                    placeholder="Pounds"
                    v-model="unitsModel.weightLbs"
                    :is-error="$v.unitsModel.weightLbs.$error"
                    :error-text="$v.unitsModel.weightLbs.$error ? 'Please enter valid pounds between 0 to 14' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    lbs
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
              <div v-if="userWeightPreference === 'kg'"  class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_weight"
                    name="txt_weight"
                    type="number"
                    placeholder="Weight"
                    v-model="model.weightGrams"
                    :is-error="$v.model.weightGrams.$error"
                    :error-text="$v.model.weightGrams.$error ? 'Please enter a valid weight between 25kg to 250kg' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    kg
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
            </div>
          </div>

          <div class="gro__input">
            <label class="gro__input__label">Applicant's current height*</label>
            <div class="row mb-4">
              <div v-show="userHeightPreference === 'ftin'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_feet"
                    name="txt_feet"
                    type="number"
                    placeholder="Feet"
                    v-model="unitsModel.heightFt"
                    :is-error="$v.unitsModel.heightFt.$error"
                    :error-text="$v.unitsModel.heightFt.$error ? 'Please enter valid feet between 3 to 7' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    ft
                  </div>
                </gro-input>
              </div>
              <div v-show="userHeightPreference === 'ftin'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_inches"
                    name="txt_inches"
                    type="number"
                    placeholder="Inches"
                    v-model="unitsModel.heightIn"
                    :is-error="$v.unitsModel.heightIn.$error"
                    :error-text="$v.unitsModel.heightIn.$error ? 'Please enter valid inches between 0 to 12' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    in
                  </div>
                </gro-input>
              </div>
              <div v-show="userHeightPreference === 'cm'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_height"
                    name="txt_height"
                    placeholder="Height"
                    type="number"
                    v-model="model.heightCm"
                    :is-error="$v.model.heightCm.$error"
                    :error-text="$v.model.heightCm.$error ? 'Please enter a valid height between 50 to 255 centimetres' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    cm
                  </div>
                </gro-input>
              </div>
            </div>

          </div>




          <!--          <div class="row mb-4">-->
<!--            <div class="col">-->
<!--              <gro-input-->
<!--                  label="Applicant's current weight *"-->
<!--                  class="hide_step_arrows"-->
<!--                  id="txt_weight"-->
<!--                  name="txt_weight"-->
<!--                  type="number"-->
<!--                  placeholder="Weight"-->
<!--                  v-model="model.weightGrams"-->
<!--                  :error-text="$v.model.weightGrams.$error ? 'Please enter a valid weight between 25kg to 250kg' : ''"-->
<!--                  :spinner="false">-->
<!--                <div slot="rightIcon">-->
<!--                  kg-->
<!--                </div>-->
<!--              </gro-input>-->
<!--            </div>-->
<!--            <div class="col">-->
<!--              <gro-input-->
<!--                  label="Applicant's current height *"-->
<!--                  id="txt_height"-->
<!--                  name="txt_height"-->
<!--                  placeholder="Height"-->
<!--                  type="number"-->
<!--                  v-model="model.heightCm"-->
<!--                  :error-text="$v.model.heightCm.$error ? 'Please enter a valid height between 50 to 255 centimetres' : ''"-->
<!--                  :spinner="false">-->
<!--                <div slot="rightIcon">-->
<!--                  cm-->
<!--                </div>-->
<!--              </gro-input>-->
<!--            </div>-->
<!--          </div>-->

          <div class="gro__select gro__input mb-3">
            <label for="txt_ethnicity" class="gro__input__label"  :class="{'has_error': $v.model.ethnicity.$error}">Ethnicity? *</label>
            <select id="txt_ethnicity" v-model="model.ethnicity"
                    class="gro__select__native__element gro__input__element">
              <option v-for="item, index in ethnicities" :key="`item-${index}`" :label="item.label" :value="item.enumValue">{{item.label}}</option>
            </select>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">
                Do you drink alcohol? *
              </label>
            </div>
            <div class="d-flex">
              <div class="d-inline-flex">
                <gro-radio v-model="model.drinkAlcohol" :value="true" label="Yes"
                           :has-error="$v.model.drinkAlcohol.$error"></gro-radio>
              </div>
              <div class="d-inline-flex pl-3">
                <gro-radio v-model="model.drinkAlcohol" :value="false" label="No"
                           :has-error="$v.model.drinkAlcohol.$error"></gro-radio>
              </div>
            </div>
          </div>

          <div v-show="model.drinkAlcohol === true">
            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have a drink containing alcohol?</label>
              <select id="txt_alcoholHowOften" v-model="model.alcoholFrequency"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in alcoholFrequencyList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_standardDrinks" class="gro__input__label">How many standard drinks containing alcohol do you have on a typical day?</label>
              <select id="txt_standardDrinks" v-model="model.dailyCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option v-for="(item, index) in dailyAlcoholList" :key="`item-${index}`" :value="item.enumValue" :label="item.label"></option>
              </select>
            </div>

            <div class="gro__select gro__input mb-3">
              <label for="txt_alcoholHowOften" class="gro__input__label">How often do you have six or more drinks on one occasion?</label>
              <select id="alcoholHowOftenSixOrMore" v-model="model.overSixCountAlcohol"
                      class="gro__select__native__element gro__input__element">
                <option :value="null" label="-- Please select --"></option>
                <option value="Daily" label="Daily or almost daily">Daily or almost daily</option>
                <option value="Weekly" label="Weekly">Weekly</option>
                <option value="Monthly" label="Monthly">Monthly</option>
                <option value="LessThanMonthly" label="Less than monthly">Less than monthly</option>
                <option value="Never" label="Never">Never</option>
              </select>
            </div>
          </div>


          <div class="divider mb-4"></div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Applicant's GP surgery</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.gp"
                id="ajax3"
                label="name"
                track-by="name"
                placeholder="Type to search for a surgery..."
                open-direction="bottom"
                :options="surgeries"
                :multiple="false"
                :searchable="true"
                :loading="isSurgeriesLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindSurgeries">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedSurgery"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Health conditions</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.conditions"
                id="ajax"
                label="name"
                track-by="name"
                placeholder="Type to search for a condition..."
                open-direction="bottom"
                :options="conditions"
                :multiple="true"
                :searchable="true"
                :loading="isConditionsLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindConditions">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedConditions"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>


          <div class="mb-3">
            <div class="gro__input">
              <label class="gro__input__label">Medications</label>
            </div>
            <multiselect
                class="gro__multi__select"
                v-model="model.medications"
                id="ajax2"
                label="name"
                track-by="name"
                placeholder="Type to search for a medication..."
                open-direction="bottom"
                :options="medications"
                :multiple="true"
                :searchable="true"
                :loading="isMedicationsLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="10"
                :limit="10"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFindMedications">
              <template slot="noOptions">
                No results to show, begin typing
              </template>
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="selectedMedications"
                     @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
            </multiselect>
          </div>

          <gro-button
              :loading="isProcessing"
              :disabled="isProcessing"
              loading-title="Processing..."
              style="position: relative; z-index: 0"
              class="w-100"
              type="submit"
              size="md"
              gradient
              glowing
              centered>
            Save
          </gro-button>

        </div>
      </gro-card>
    </form>
  </div>
</template>

<script>
import referralClient from "@/helpers/referralClient";
import {validationMixin} from 'vuelidate'
import conditionsMultiSelectMixin from '@/mixins/conditionsMultiSelectMixin'
import medicationsMultiSelectMixin from '@/mixins/medicationsMultiSelectMixin'
import surgeriesSelectMixin from "@/mixins/surgeriesSelectMixin"
import {required, requiredIf, email, sameAs, minLength, maxLength, minValue, maxValue,numeric} from 'vuelidate/lib/validators'
import moment from "moment";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import UnitPreferences from "@/views/Portal/_components/UnitPreferences";
const minAge = function(value) {
  let minAge = 18;
  let now = moment();
  let dobMoment = moment(value);
  let differenceInYears = now.diff(dobMoment, 'years');
  return differenceInYears >= minAge & differenceInYears < 120;
}

export default {
  name: "UpdatePatient",
  mixins: [validationMixin, conditionsMultiSelectMixin, medicationsMultiSelectMixin, surgeriesSelectMixin],
  components: {Multiselect, UnitPreferences},
  props: ['ethnicities', 'patient', 'alcoholFrequencyList', 'dailyAlcoholList', 'goalsList', 'services', 'referralServicesList'],
  validations: {
    model: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minLength: minLength(10)
      },
      dateOfBirth: {
        required,
        minAge
      },
      gender: {
        required
      },
      postcode: {
        required
      },
      ethnicity: {
        required
      },
      drinkAlcohol: {
        required
      },
      weightGrams: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'kg'
        }),
        maxValue: maxValue(250),
        minValue: minValue(25)
      },
      heightCm: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'cm'
        }),
        maxValue: maxValue(255),
        minValue: minValue(50)
      },
    },
    unitsModel: {
      weightSt: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(38),
        minValue: minValue(3)
      },
      weightLbs: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(14),
        minValue: minValue(0)
      },
      heightFt: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'ftin'
        }),
        maxValue: maxValue(7),
        minValue: minValue(3)
      },
      heightIn: {
        required: requiredIf(function () {
          return this.userHeightPreference === 'ftin'
        }),
        maxValue: maxValue(12),
        minValue: minValue(0)
      },
    }
  },
  mounted() {
    let name = this.patient.name.split(/(?<=^\S+)\s/)
    let firstName = name[0];
    let lastName = name[1];

    this.model.firstName = firstName;
    this.model.lastName = lastName;
    this.model.email = this.patient.email;
    this.model.phone = this.patient.telephone
    this.model.postcode = this.patient.location;
    this.model.ethnicity = this.patient.ethnicity;
    this.model.gender = this.patient.gender;
    this.model.gp = this.patient.gp;
    this.model.ethnicity = this.patient.ethnicity.enumValue;
    this.model.conditions = this.patient.conditions;
    this.model.medications = this.patient.medications;
    this.model.weightGrams = this.patient.weightGrams / 1000;
    this.model.heightCm = this.patient.heightCm;

    this.model.drinkAlcohol = this.patient.drinkAlcohol;
    this.model.alcoholFrequency = this.patient.alcoholFrequency ? this.patient.alcoholFrequency.enumValue : null;
    this.model.overSixCountAlcohol = this.patient.overSixCountAlcohol ? this.patient.overSixCountAlcohol.enumValue : null;
    this.model.dailyCountAlcohol = this.patient.dailyCountAlcohol ? this.patient.dailyCountAlcohol.enumValue : null;

    //setup unit model
    if  (this.userWeightPreference === 'ftin') {
      let ftin = this.patient.heightCm * 0.032808;

      this.unitsModel.heightFt = Math.floor(ftin);
      this.unitsModel.heightIn =  (12 * (ftin - Math.floor(ftin))).toFixed(0);
    }

    if  (this.userWeightPreference === 'stlbs') {
      let stlbs = this.patient.weightGrams * 0.00015747;
      this.unitsModel.weightSt = Math.floor(stlbs);
      this.unitsModel.weightLbs = (14 * (stlbs - Math.floor(stlbs))).toFixed(0);
    } else {
      this.model.weightGrams = this.patient.weightGrams / 1000;
    }
  },
  data() {
    return {
      isProcessing: false,
      model: {
        firstName: '',
        lastName: '',
        email: null,
        phone: null,
        dateOfBirth: Date.parse(moment(this.patient.dateOfBirth).format('YYYY-MM-DD')),
        gender: '',
        postcode: '',
        weightGrams: null,
        heightCm: null,
        ethnicity: '',
        gp: null,
        conditions: [],
        medications: [],
        healthGoals: [],
        goals: [],
        referredServices: [],
        preferredContact: null,
        drinkAlcohol: null,
        alcoholFrequency: null,
        dailyCountAlcohol: null,
        overSixCountAlcohol: null,
        services: [],
      },
      unitsModel: {
        weightSt: null,
        weightLbs: null,
        heightFt: null,
        heightIn: null,
      }
    }
  },
  methods: {
    updatePatient() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.model.goals = this.goalsList
          .filter(x => x.checked === true)
          .map(x => ({name: x.name, goalId: x.id}))

      this.model.services = this.services
          .filter(x => x.checked === true)
          .map(x => ({id: x.id}))

      this.model.referredServices = this.referralServicesList
          .filter(x => x.checked === true)
          .map(x => ({id: x.id}))


      this.isProcessing = true;

      if (this.model.drinkAlcohol === false) {
        this.model.alcoholFrequency = null;
        this.model.overSixCountAlcohol = null;
        this.model.dailyCountAlcohol = null;
      }

      let updatedModelForApi = {...this.model};

      updatedModelForApi.dateOfBirth = moment(this.model.dateOfBirth).format('YYYY-MM-DD');


      if (this.userWeightPreference === 'stlbs') {
        let totalStones = (this.unitsModel.weightSt * 14);
        let totalLbs = parseInt(totalStones) + parseInt(this.unitsModel.weightLbs);
        let lbsToGrams = totalLbs / 0.0022046;
        updatedModelForApi.weightGrams = parseInt(lbsToGrams);
      } else if(this.userWeightPreference === 'kg') {
        updatedModelForApi.weightGrams = parseInt(this.model.weightGrams * 1000);
      }

      if (this.userHeightPreference === 'ftin') {
        let totalFt = (this.unitsModel.heightFt * 12)
        let totalInches = parseInt(totalFt) + parseInt(this.unitsModel.heightIn)
        let inchesToCm = totalInches / 0.39370;
        updatedModelForApi.heightCm = parseInt(inchesToCm)
      } else if(this.userHeightPreference === 'cm') {
        updatedModelForApi.heightCm = parseInt(this.model.heightCm);
      }



      for (const key of Object.keys(updatedModelForApi)) {
        if (updatedModelForApi[key] === '' || updatedModelForApi[key] === null || updatedModelForApi[key] === []) {
          delete updatedModelForApi[key];
        }
      }

      console.log(updatedModelForApi);

      referralClient.put(`/Lead/${this.patient.id}`, updatedModelForApi)
          .then((res) => {
            this.$emit('success', true);
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    }
  },
  computed: {
    userWeightPreference () {
      return this.$store.getters['User/getUserPreferences'].weight
    },
    userHeightPreference () {
      return this.$store.getters['User/getUserPreferences'].height
    }
  }
}
</script>

<style lang="scss">


</style>