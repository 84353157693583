<template>
  <div>
    <form @submit.prevent="addWeightReading">
      <gro-card :bordered="false">
        <div slot="body">
          <h2 class="mb-3">Add a weight reading</h2>
          <div class="mb-3">Update the personal details of an applicant. Referral status can be managed from the Patient Detail page.</div>

          <div class="gro__input">
            <label class="gro__input__label">Applicant's current weight*</label>
            <div class="row mb-4">
              <div v-if="userWeightPreference === 'stlbs'" class="col">
                <gro-input
                    class="remove_number_stepper"
                    id="txt_stones"
                    name="txt_stones"
                    type="number"
                    placeholder="Stones"
                    v-model="unitsModel.weightSt"
                    :is-error="$v.unitsModel.weightSt.$error"
                    :error-text="$v.unitsModel.weightSt.$error ? 'Please enter valid stones between 3 to 38' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    st
                  </div>
                </gro-input>
              </div>
              <div v-if="userWeightPreference === 'stlbs'" class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_pounds"
                    name="txt_pounds"
                    type="number"
                    placeholder="Pounds"
                    v-model="unitsModel.weightLbs"
                    :is-error="$v.unitsModel.weightLbs.$error"
                    :error-text="$v.unitsModel.weightLbs.$error ? 'Please enter valid pounds between 0 to 14' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    lbs
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
              <div v-if="userWeightPreference === 'kg'"  class="col d-flex">
                <gro-input
                    class="w-100 pr-2 remove_number_stepper"
                    id="txt_weight"
                    name="txt_weight"
                    type="number"
                    placeholder="Weight"
                    v-model="model.weightGrams"
                    :is-error="$v.model.weightGrams.$error"
                    :error-text="$v.model.weightGrams.$error ? 'Please enter a valid weight between 25kg to 250kg' : ''"
                    :spinner="false">
                  <div slot="rightIcon">
                    kg
                  </div>
                </gro-input>
                <unit-preferences></unit-preferences>
              </div>
            </div>
          </div>

          <div class="gro__select gro__input mb-3">
            <label for="weightWeekNumber" class="gro__input__label">Select week (optional)</label>
            <select id="weightWeekNumber" v-model="model.weightWeekNumber"
                    class="gro__select__native__element gro__input__element">
              <option :value="null" label="-- Please select --"></option>
              <option :value="12" label="12">12</option>
              <option :value="26" label="26">26</option>
              <option :value="52" label="52">52</option>
              <option :value="0" label="Other">Other</option>
            </select>
          </div>

          <gro-inline-date-of-birth
              class="mb-3"
              label="Date of measurement"
              v-model="model.weightRecordedAt"
          />


          <gro-button
              :loading="isProcessing"
              :disabled="isProcessing"
              loading-title="Processing..."
              style="position: relative; z-index: 0"
              class="w-100"
              type="submit"
              size="md"
              gradient
              glowing
              centered>
            Save
          </gro-button>

        </div>
      </gro-card>
    </form>
  </div>
</template>

<script>
import referralClient from "@/helpers/referralClient";
import {validationMixin} from 'vuelidate'
import conditionsMultiSelectMixin from '@/mixins/conditionsMultiSelectMixin'
import medicationsMultiSelectMixin from '@/mixins/medicationsMultiSelectMixin'
import surgeriesSelectMixin from "@/mixins/surgeriesSelectMixin"
import {required, requiredIf, email, sameAs, minLength, maxLength, minValue, maxValue,numeric} from 'vuelidate/lib/validators'
import moment from "moment";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import UnitPreferences from "@/views/Portal/_components/UnitPreferences";
const minAge = function(value) {
  let minAge = 18;
  let now = moment();
  let dobMoment = moment(value);
  let differenceInYears = now.diff(dobMoment, 'years');
  return differenceInYears >= minAge & differenceInYears < 120;
}

export default {
  name: "AddWeightReading",
  mixins: [validationMixin, conditionsMultiSelectMixin, medicationsMultiSelectMixin, surgeriesSelectMixin],
  props:['patient'],
  components: {UnitPreferences},
  validations: {
    model: {
      weightGrams: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'kg'
        }),
        maxValue: maxValue(250),
        minValue: minValue(25)
      },
    },
    unitsModel: {
      weightSt: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(38),
        minValue: minValue(3)
      },
      weightLbs: {
        required: requiredIf(function () {
          return this.userWeightPreference === 'stlbs'
        }),
        maxValue: maxValue(14),
        minValue: minValue(0)
      },
    }
  },
  mounted() {
    let name = this.patient.name.split(/(?<=^\S+)\s/)
    let firstName = name[0];
    let lastName = name[1];
    this.model.firstName = firstName;
    this.model.lastName = lastName;
    this.model.email = this.patient.email;
    this.model.phone = this.patient.telephone
    this.model.postcode = this.patient.location;
    this.model.gender = this.patient.gender;
  },
  data() {
    return {
      isProcessing: false,
      model: {
        firstName: '',
        lastName: '',
        email: null,
        phone: null,
        dateOfBirth: Date.parse(moment(this.patient.dateOfBirth).format('YYYY-MM-DD')),
        gender: '',
        postcode: '',
        weightGrams: null,
        weightWeekNumber: null,
        weightRecordedAt: null,
      },
      unitsModel: {
        weightSt: null,
        weightLbs: null,
      }
    }
  },
  methods: {
    addWeightReading() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isProcessing = true;

      let updatedModelForApi = {...this.model};

      updatedModelForApi.dateOfBirth = moment(this.model.dateOfBirth).format('YYYY-MM-DD');
      updatedModelForApi.weightRecordedAt = moment(this.model.weightRecordedAt).format('YYYY-MM-DD');

      if (this.userWeightPreference === 'stlbs') {
        let totalStones = (this.unitsModel.weightSt * 14);
        let totalLbs = parseInt(totalStones) + parseInt(this.unitsModel.weightLbs);
        let lbsToGrams = totalLbs / 0.0022046;
        updatedModelForApi.weightGrams = parseInt(lbsToGrams);
      } else if(this.userWeightPreference === 'kg') {
        updatedModelForApi.weightGrams = parseInt(this.model.weightGrams * 1000);
      }

      console.log(updatedModelForApi);

      referralClient.put(`/Lead/${this.patient.id}`, updatedModelForApi)
          .then((res) => {
            this.$emit('success', true);
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    }
  },
  computed: {
    userWeightPreference () {
      return this.$store.getters['User/getUserPreferences'].weight
    },
  }
}
</script>

<style lang="scss">


</style>